import React, { Fragment, useEffect, useState } from "react";
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileEdit from "../../../assets/media/icon-file-edit.svg";
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconRenewel2 from "../../../assets/media/icon-renewel-2.svg";
import iconTimeSquare from "../../../assets/media/icon-time-square.svg";
import iconSwap from "../../../assets/media/icon-swap.svg";
import iconTick from "../../../assets/media/icon-tick.svg";
import iconSearch from "../../../assets/media/icon-search.svg";

import { Link } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../applications";
import Pagination from "./appPagination";
import { setActiveItem } from "../../../store/reducers/counterSlice";
import { fetchApplications } from "../../../store/reducers/applicationSlice";
import { AreaChartWithFilters, Filter } from "./chartComponents";
import DatePicker from "react-datepicker";

// export const formatDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const options = { year: "numeric", month: "long", day: "2-digit" };
//     return date.toLocaleDateString(undefined, options);
// };

function CheckboxFilter({ checkboxes, setCheckboxes, setSearchTerm }) {
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (value === "All") {
      setCheckboxes((prevCheckboxes) => {
        const newCheckboxes = { ...prevCheckboxes };
        for (const key in newCheckboxes) {
          newCheckboxes[key] = checked;
        }
        return newCheckboxes;
      });
      setSearchTerm("");
    } else {
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [value]: checked,
      }));
      setSearchTerm("");
    }
  };

  return (
    <div className="boardFilter">
      {Object.keys(checkboxes)?.map((checkboxValue) => (
        <div className="form-check form-check-inline" key={checkboxValue}>
          <input
            className="form-check-input"
            type="checkbox"
            value={checkboxValue}
            checked={checkboxes[checkboxValue]}
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label">{checkboxValue}</label>
        </div>
      ))}
    </div>
  );
}

function countStateOccurrences(apps) {
  const stateCounts = {};

  apps?.forEach((app) => {
    const state = app.state;
    if (stateCounts[state]) {
      stateCounts[state]++;
    } else {
      stateCounts[state] = 1;
    }
  });

  return stateCounts;
}

const stateButtonMap = {
  in_process: {
    className: "btnProcess",
    text: "In Process",
    state: "in_process",
  },
  pending: { className: "btnPending", text: "Pending", state: "pending" },
  approved: { className: "btnApproved", text: "Approved", state: "approved" },
  rejected: { className: "btnRejected", text: "Rejected", state: "rejected" },
  discrepancies: {
    className: "btnDiscrepancies",
    text: "Discrepancies",
    state: "discrepancies",
  },
  pending_from_dw: {
    className: "btnPendingFromDW",
    text: "Pending From DW",
    state: "pending_from_dw",
  },
  drafts: { className: "btnDrafts", text: "Drafts", state: "drafts" },
  renewal: { className: "btnRenewal", text: "Renewal", state: "renewal" },
};

const AppDashboard = () => {
  const initialState = {
    All: true,
    "In Process": false,
    Pending: false,
    Rejected: false,
    Approved: false,
    // Discrepancies: false,
    // 'Pending From DW': false,
    // Drafts: false,
    // Renewal: false,
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState(initialState);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const applications = countStateOccurrences(
    useSelector((state) => state.applications.data)
  );
  const applicationRecords = useSelector((state) => state.applications.data);

  const dispatch = useDispatch();
  const filtered = (search = "") => {
    const minifiedData = applicationRecords.filter(
      (record) =>
        record.state === "in_process" ||
        record.state === "pending_from_dw" ||
        record.state === "rejected" ||
        record.state === "approved"
    );
    console.log({ minifiedData });
    const filteredData = minifiedData?.map((item, i) => {
      let stateInfo = stateButtonMap[item.state];
      if (
        (stateInfo.state === item.state && checkboxes[stateInfo.text]) ||
        checkboxes["All"] === true
      ) {
        return {
          ...item,
          created_at: formatDate(item.created_at),
          updated_at: formatDate(item.updated_at),
          stateText: stateButtonMap[item.state].text,
        };
      }
    });
    const filterUndef = filteredData?.filter((el) => el !== undefined);
    const finalArray = filterUndef?.filter((item) => {
      for (const key in item) {
        if (
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });

    setData(finalArray);
    return finalArray;
    // setData(filteredData)
    // return filteredData
  };
  useEffect(() => {
    const performSearch = async () => {
      const dataSearch = await filtered(searchTerm);
      setData(dataSearch);
    };
    const debounceTimer = setTimeout(() => {
      performSearch();
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);
  const handleInputChange = (event) => setSearchTerm(event.target.value);
  useEffect(() => setData(filtered()), [applicationRecords, checkboxes]);

  const accessToken = useSelector((state) => state.auth.user.access_token);

  useEffect(() => {
    let queryParams = {};
    if (startDate && endDate) {
      queryParams.start_date = startDate.toISOString();
      queryParams.end_date = endDate.toISOString();
    }
    dispatch(
      fetchApplications({ accessToken: accessToken, queryParams: queryParams })
    );
  }, [accessToken, dispatch, dateRange]);

  const generateRandomData = (count) => {
    const startDate = new Date("2023-08-01");
    const data = [];
    let value = 100;

    for (let i = 0; i < count; i++) {
      const dateString = startDate.toISOString().slice(0, 10);
      data.push({ date: dateString, value });
      startDate.setDate(startDate.getDate() + 1);
      value += parseInt(Math.random() * 20 - 10); // Varying values up and down
    }

    return data;
  };

  const initialData = generateRandomData(50);
  const [selectedRange, setSelectedRange] = useState("week");
  const [filteredData, setFilteredData] = useState(initialData);

  const handleFilterChange = (range) => {
    setSelectedRange(range);
    // Update filteredData based on the selected range
    // You can use a library like date-fns for date manipulation
    // Example: update filteredData to contain data for the selected range
    // setFilteredData(newData);
  };

  const applicationLoading = useSelector((state) => state.applications.loading);
  const userLoading = useSelector((state) => state.auth.loading);

  if (applicationLoading || userLoading) {
    return <p>loading</p>;
  }

  const getTotalPages = () => {
    if (Number.isInteger(data?.length / 10)) return data?.length / 10;
    return Math.floor(data?.length / 10) + 1;
  };

  return (
    <div>
      <Navbar />
      <Header />
      <div className="wrapper ">
        <div className="row01 ">
          <div className="boxApp">
            <h2>My Applications</h2>
            <div className="d-flex mb-4" style={{ width: "250%" }}>
              <div className="appState stateProcess">
                <h3>
                  <img src={iconRenewel2} alt="" />
                  <span>{applications?.in_process}</span>
                </h3>
                <h4>In Process</h4>
                <Link
                  onClick={() => {
                    dispatch(setActiveItem("In Process"));
                  }}
                  to={"/applications"}
                >
                  View All
                </Link>
              </div>
              <div className="appState statePending">
                <h3>
                  <img src={iconTimeSquare} alt="" />
                  <span>{applications?.pending}</span>
                </h3>
                <h4>Pending</h4>
                <Link
                  onClick={() => {
                    dispatch(setActiveItem("Pending"));
                  }}
                  to={"/applications"}
                >
                  View All
                </Link>
              </div>
              <div className="appState stateDiscrepancies">
                <h3>
                  <img src={iconSwap} alt="" />
                  <span>{applications?.discrepancies}</span>
                </h3>
                <h4>Discrepancies</h4>
                <Link to={"/discrepancies"}>View All</Link>
              </div>
              <div className="appState stateApproved">
                <h3>
                  <img src={iconTick} alt="" />
                  <span>{applications?.approved}</span>
                </h3>
                <h4>Approved</h4>
                <Link
                  onClick={() => {
                    dispatch(setActiveItem("Approved"));
                  }}
                  to={"/applications"}
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
          {/* <div className='boxForm' style={{ width: '550px' }}>
                        <h2>Total Submitted Forms</h2>
                        <div className='appStates1' >
                            <AreaChartWithFilters data={filteredData} />
                        </div>
                    </div> */}
        </div>
        <div className="boxAppBoard boxWhite">
          <div
            style={{ flexWrap: "wrap", gap: "10px" }}
            className="boardHeader"
          >
            <CheckboxFilter
              setSearchTerm={setSearchTerm}
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
            />
            <div
              style={{
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
              className="boardHeadRight"
            >
              <div className="searchForm">
                <input
                  type="text"
                  placeholder="input search text"
                  onChange={handleInputChange}
                  value={searchTerm}
                />
                <button>
                  <img src={iconSearch} alt="" />
                </button>
              </div>
              <div className="datePicker">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                  className="date-range-input"
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
          </div>
          <div className="boardContent">
            <div className="boardThead">
              <div className="boardCol w-85">S.NO.</div>
              <div className="boardCol w-165">Application No.</div>
              <div className="boardCol w-adj1">Application Name</div>
              <div className="boardCol w-165">Submitted Date</div>
              <div className="boardCol w-165">Registered Date</div>
              <div className="boardCol w-165">Status</div>
              <div className="boardCol w-165">Attachments</div>
              <div className="boardCol w-110"></div>
            </div>
            {data?.map((application, index) => {
              let stateInfo = stateButtonMap[application.state];
              return (
                <Fragment key={index}>
                  <div
                    className={`boardTrow ${
                      (currentPage === 1
                        ? currentPage
                        : (currentPage - 1) * 10 + 1) <=
                        index + 1 &&
                      index < (currentPage === 1 ? 10 : currentPage * 10)
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <div className="boardCol w-85">{index + 1}</div>
                    <div className="boardCol w-165">GB-4939907</div>
                    <div className="boardCol w-adj1">
                      <div className="boardTitle">
                        <strong>{application.name}</strong>
                        <span>{application.form}</span>
                      </div>
                    </div>
                    <div className="boardCol w-165">
                      {application.updated_at}
                    </div>
                    <div className="boardCol w-165">
                      {application.created_at}
                    </div>
                    <div className="boardCol w-165">
                      {" "}
                      <Link
                        className={`boardButton ${stateInfo.className}`}
                        to={"/"}
                      >
                        {/* {stateInfo.text} */}
                        {application.stateText}
                      </Link>
                    </div>
                    <div className="boardCol w-165">
                      <div className="boardDownload">
                        <Link to={"/"}>
                          <img src={iconFilePdf} alt="" />
                        </Link>
                        <Link to={"/"}>
                          <img src={iconFileDoc} alt="" />
                        </Link>
                        <Link to={"/"}>
                          <img src={iconFileImage} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="boardCol w-110">
                      <div className="boardActions">
                        {index % 2 === 0 ? (
                          <Link to={"/"}>
                            <img src={iconFileDelete} alt="" />
                          </Link>
                        ) : (
                          <Link to={"/"}>
                            <img src={iconFileEdit} alt="" />
                          </Link>
                        )}
                        <Link to={"/"}>
                          <img src={iconFileDownload} alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={getTotalPages}
          />
        </div>
      </div>
    </div>
  );
};
export default AppDashboard;
