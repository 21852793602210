// reducers/employeeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showError, showSuccess } from "../../helpers/toast";
import _ from "lodash";
import qs from "qs";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const initialState = {
  createLoading: false,
  fetchLoading: false,
  createError: null,
  fetchError: null,
  message: null,
  users: [],
};

export const deleteEmployee = createAsyncThunk(
  "/employees/deleteEmployee",
  async ({ email, accessToken }) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.post("/users/delete", { email }, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load employee");
    }
  }
);

export const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `${data.accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.post("/users", data.userData, config);
      return response.data;
    } catch (error) {
      throw new Error(
        error?.response?.data?.message || "Failed to add employee"
      );
    }
  }
);

export const fetchEmployee = createAsyncThunk(
  "employee/fetchEmployee",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      let url = "/users";
      if (data?.queryParams && !_.isEmpty(data?.queryParams)) {
        url = `${url}?${
          data?.queryParams instanceof Object
            ? qs.stringify(data?.queryParams)
            : data?.queryParams
        }`;
      }
      const response = await API.get(url, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load employee");
    }
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.message = action.payload.message;
        showSuccess(state.message);
      })
      .addCase(addEmployee.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.createLoading = false;
        state.message = action.payload.message;
        state.createError = null;
        showSuccess(state.message);
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.createLoading = false;
        state.createError = action.error.message;
        state.message = action.error.message;
        showError(state.createError);
      })
      .addCase(fetchEmployee.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchEmployee.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.users = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchEmployee.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.users = initialState.users;
        state.message = null;
      });
  },
});

export default employeeSlice.reducer;
